<template>
  <div class="pages-wrap"
       :class="{
          'active-mobile': mobileMenu,
          'margin-top': isMarginTop,
       }"
       :style="[activeRoute === '/'? styleHome: null]"
  >
    <div class="pages-wrap__top"
         v-if="activeRoute !== '/'">
      <img src="~assets/img/page-top-img.svg"
           alt="img">
      <div class="container__top">
        <div class="pages-wrap__title" :class="classPage">{{ titlePage }}</div>
      </div>
    </div>
    <div
        :style="[activeRoute === '/'? styleHome: null]"
        :class="{ 'container-full': activeRoute === '/' }"
        class="container"
    >
      <router-view/>
    </div>
    <Footer v-if="activeRoute !== '/'"/>
  </div>
</template>
<style lang="scss" scoped>
@import "src/assets/style/var-style";
.container__top{
  position: relative;
  left: 144px;
  top: 43px;
}
@media screen and (max-width: 1200px) {
  .container__top {
    left: auto;
    margin: 0 auto;
    position: relative;
    max-width: 832px;
  }
}

@media screen and (max-width: 896px){
  .container__top {
    max-width: 640px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
.margin-top {
  margin-top: 80px;
}
.pages-wrap {
  position: relative;
  margin-left: 256px;
  min-width: calc(100vw - 273px);
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  transition: margin-left .3s ease-in-out;
  background: #F5F5F5;

  .container {
    width: calc(100vw - 256px);
    padding: 0;
  }

  &__top {
    height: 288px;
    width: 100%;
    position: relative;
    background: $background-main;

    img {
      width: auto;
      height: 100%;
      min-height: 100%;
      position: absolute;
      right: -17px;
    }
  }

  &__title {
    z-index: 1;
    color: #fff;
    font-weight: 700;
    font-size: 42px;
    letter-spacing: -0.02em;
    line-height: 48px;
    position: absolute;
    text-transform: uppercase;
    width: min-content;

    &.no-wrap {
      white-space: nowrap;
    }
  }
}

@media screen and (max-width: 1456px) {
  .pages-wrap {
    margin-left: 0;
  }
  .page__content-wrapper {
    padding-right: 0;
    margin: 0 auto;
  }
  .pages-wrap .container {
    margin-left: auto;
    margin-right: auto;

    &.container-full {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1000px) {
  .pages-wrap .container {
    width: 100%;
  }
}

@media screen and (max-width: 869px) {
  .pages-wrap__top {
    height: 269px;
    overflow: hidden;
  }
}

@media screen and (max-width: 420px) {
  .pages-wrap__top {
    height: 144px;

    img {
      height: inherit;
      min-height: inherit;
    }
  }
  .pages-wrap__title {
    font-size: 28px;
    line-height: 115%;
    top: 21px;
  }
  .pages-wrap__top img {
    right: -20px;
  }
}
</style>
<script>
import Footer from "@/components/Footer/Footer";

export default {
  props: ["mobileMenu"],
  components: {
    Footer
  },
  data() {
    return {
      activeRoute: '',
      styleHome: {
        minHeight: '100vh',
        marginTop: '0',
        padding: '0',
      },
      titlePage: '',
      classPage: '',
      isMarginTop: false,
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.handleScroll();
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {
      this.isMarginTop = window.pageYOffset > 0 || window.innerWidth >= 1456
    }
  },
  watch: {
    $route(to) {
      this.titlePage = to.meta.title
      this.classPage = to.meta.class
      this.activeRoute = to.path

      this.handleScroll();
    }
  }
}
</script>
