<template>
  <div class="header" v-bind:class="{ mobile: activeRoute === '/', position: isPosition || activeRoute === '/' }"
       v-bind:style="[scrollStyle]">
    <router-link to="/"
                 v-if="this.activeRoute !== '/'"
                 class="header__logo">
      <img src="~assets/img/logo.svg" alt="Logo">
    </router-link>
    <div class="title-header" v-if="this.activeRoute !== '/'">
      Цифровой брендбук
    </div>
    <button class="nav-menu__mobile-btn"
            v-if="this.activeRoute !== '/'"
            v-on:click="changeMobile()">
      <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1C0 0.447715 0.447716 0 1 0H23C23.5523 0 24 0.447715 24 1C24 1.55228 23.5523 2 23 2H1C0.447715 2 0 1.55228 0 1ZM0 10C0 9.44771 0.447716 9 1 9H23C23.5523 9 24 9.44771 24 10C24 10.5523 23.5523 11 23 11H1C0.447715 11 0 10.5523 0 10ZM24 19C24 18.4477 23.5523 18 23 18H1C0.447715 18 0 18.4477 0 19C0 19.5523 0.447716 20 1 20H23C23.5523 20 24 19.5523 24 19Z" fill="white"/>
      </svg>
    </button>
    <button class="nav-menu__mobile-btn"
            v-if="this.activeRoute === '/'"
            v-on:click="changeMobile()">
      <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1C0 0.447715 0.447716 0 1 0H23C23.5523 0 24 0.447715 24 1C24 1.55228 23.5523 2 23 2H1C0.447715 2 0 1.55228 0 1ZM0 10C0 9.44771 0.447716 9 1 9H23C23.5523 9 24 9.44771 24 10C24 10.5523 23.5523 11 23 11H1C0.447715 11 0 10.5523 0 10ZM24 19C24 18.4477 23.5523 18 23 18H1C0.447715 18 0 18.4477 0 19C0 19.5523 0.447716 20 1 20H23C23.5523 20 24 19.5523 24 19Z" fill="white"/>
      </svg>
    </button>
  </div>
</template>
<script>
export default {
  props:["changeMobile", "mobileMenu"],
  data(){
    return{
      winOffsetY: 0,
      activeRoute: '',
      scrollStyle: {
        top: 0
      },
      isPosition: true
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {
      if (window.innerWidth < 1456) {
        if (this.winOffsetY > window.pageYOffset || this.winOffsetY < 80) {
          this.scrollStyle.top = 0
        } else {
          this.scrollStyle.top = '-80px'
        }
        this.winOffsetY = window.pageYOffset
      } else {
        this.scrollStyle.top = '0'
      }

      if (this.$route.fullPath !== '/' && window.innerWidth < 1456) {
        this.isPosition = window.pageYOffset > 0
      }
    }
  },
  watch: {
    $route(to){
      this.activeRoute = to.path
      this.handleScroll();
    },
  },
}
</script>
<style lang="scss" scoped>
@import "src/assets/style/var-style";
*{
  font-family: 'suisse_intl', sans-serif;
}
.position {
  position: fixed;
}
.header{
  width: 100%;
  top: 0;
  left: 0;
  background: $background-main;
  color: #fff;
  padding: 24px;
  height: 80px;
  max-height: 80px;
  display: flex;
  align-items: center;
  z-index: 4;
  transition: top .2s ease-in-out;
  &__logo{
    width: 256px;
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  &.mobile{
    top: -127px;
    background: rgba(0,0,0,0);
  }
}
.title-header{
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #F3F8FA;
  margin-left: -24px;
  padding-top: 6px;
}
.logo-text{
  color: #fff;
  text-transform: uppercase;
  font-size: 22px;
  padding: 0 2px;
}
.nav-menu__mobile-btn{
  position: absolute;
  width: 30px;
  height: 30px;
  display: none;
  background-color: inherit;
  border: none;
  cursor: pointer;
  color: #fff;
  left: calc(100% - 50px);
  &:focus{
    outline: none;
  }
}

@media screen and (max-width: 1456px){
  .header.mobile{
    top: 0;
  }
  .nav-menu__mobile-btn{
    display: flex;
  }
  .title-header{
    display: none;
  }
}
@media screen and (max-width: 800px){

  .page__contact{
    flex-direction: column;
  }
  .page__contact-center{
    width: 100%;
  }
}
@media screen and (max-width: 768px){
  .header{
    z-index: 3;
  }
}
</style>
