<template>
  <div id="app">
    <Header :changeMobile="changeMobile"
            :mobileMenu="mobileMenu"/>
    <NavMenu v-bind:class="{ mobile: activeRoute !== '/' }"
             :changeMobile="changeMobile"
             :mobileMenu="mobileMenu"/>
    <PagesWrap :mobileMenu="mobileMenu"/>
    <div class="overlay-menu"
         v-bind:class="{ active: !mobileMenu }" v-on:click="this.changeMobile">
    </div>
  </div>
</template>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
// font-family: 'Open Sans', sans-serif;

@import url("assets/fonts/Suisse-Intl/stylesheet.css");
//font-family: 'suisse_intl';
@import url("assets/fonts/formular/stylesheet.css");
//font-family: 'formular';

@import "assets/style/var-style";
@import "assets/style/text-style";

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'formular', 'suisse_intl', sans-serif;
}

#app{
  background: $body-background;
  overflow-x: hidden;
}

.overlay-menu{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background: rgba(0,0,0,0);
  transition: z-index .5s ease-in-out,
  background-color .5s ease-in-out;
  &.active{
    z-index: 1;
    background: rgba(0,0,0,.3);
  }
}
</style>
<script>
import Header from "@/components/Header/Header";
import NavMenu from "@/components/NavMenu/NavMenu";
import PagesWrap from "@/components/PagesWrap/PagesWrap";
export default {
  components: {
    PagesWrap,
    NavMenu,
    Header
  },
  data(){
    return {
      activeRoute: '',
      mobileMenu: true,
    }
  },
  watch: {
    $route(to){
      this.activeRoute = to.path
    }
  },
  methods:{
    changeMobile(){
      this.mobileMenu = !this.mobileMenu

      const body = document.querySelector('body');
      body.style.overflow = this.mobileMenu ? 'auto' : 'hidden'
    }
  }
}
</script>